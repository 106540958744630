<template>
  <base-section id="features" class="secondary">
    <v-responsive class="mx-auto" max-width="1350">
      <v-container fluid>
        <v-row>
          <v-col
            v-for="card in cards"
            :key="card.title"
            cols="12"
            sm="4"
            md="4"
          >
            <base-info-card align="center" v-bind="card" />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
export default {
  name: "SectionFeatures",

  data: () => ({
    cards: [
      {
        icon: "mdi-human-greeting-variant",
        title: "Help for the tough times",
        text:
          "If you're facing it, God has the answer for it. Use the topical helps list to quickly find guidance in your time of need."
      },
      {
        icon: "mdi-cellphone-sound",
        title: "Access the Bible your way",
        text:
          "Text and audio in many languages. Sync with other mobile devices. It's all up to you."
      },
      {
        icon: "mdi-book-open-page-variant",
        title: "Share the Word",
        text:
          "A great reading or witnessing tool featuring the Bible in your language."
      }
    ]
  })
};
</script>
